@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&amp;display=fallback');

@import 'admin-lte/plugins/fontawesome-free/css/all.min.css';
@import 'admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css';
@import 'admin-lte/dist/css/adminlte.min.css';
@import 'react-toastify/dist/ReactToastify.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rdt_Table {
  background-color: #343a40;
  color: #fff;
}

.rdt_TableCol {
  background-color: #343a40;
  color: #fff;
}

.rdt_TableCell {
  background-color: transparent;
  color: #fff;
}

.rdt_Pagination {
  background-color: #343a40;
  color: #fff;
}

.form-apikey:disabled, .form-apikey[readonly], .form-apikey:read-only {
  color: #6c757d;
}